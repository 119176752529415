import React from "react";

function ResizeContainer(props) {
  React.useEffect(() => {
    const resizeFnc = () => {
      document.querySelectorAll('.service-row .card, .section-tripple .box').forEach(el => {
        let height = el.offsetWidth ;
        el.style.minHeight = height + 'px';
      });
    };
    const loadListener = () => {
      resizeFnc();
    }
    resizeFnc();
    window.addEventListener('resize', resizeFnc);
    window.addEventListener('load', loadListener);
    return () => {
      window.removeEventListener('resize', resizeFnc);
      window.removeEventListener('load', loadListener);
    }
  }, []);
  return (null);
}

export default ResizeContainer;
