import React from "react";
import {Container, Row, Col, NavbarBrand} from "reactstrap";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";
import LanguageSwitch, { getCurrentLink, getCurrentLinkSwitch } from "./languageSwitch";
import { navigate } from '@reach/router';
import { scrollElementIntoView } from "./helper";

function Footer(props) {
  function handleLink(str) {
    if (typeof window !== `undefined` && ['/', '/en/', '/de/'].includes(window.location.pathname)) {
      scrollElementIntoView(document.getElementById(str));
    } else {
      navigate(getCurrentLink(props.langs, '/#' + str));
    }
  }
  return (
    <footer className="section footer">
      <Container>
        <Row>
          <Col lg="12">
            <hr />
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="navigation">
            <div className="footer-container large">
              <NavbarBrand id="brand-footer" to={getCurrentLink(props.langs, '/')} tag={Link}>
                <svg
                    viewBox="0 0 917.24 94.46">
                  <g aria-label="newww.agency">
                    <path
                        id="path841"
                        className="logo-fill black"
                        d="M6.78,47.1H25.22v8.45h.25A15.6,15.6,0,0,1,28,52a18.43,18.43,0,0,1,4-3.2,22.7,22.7,0,0,1,5.25-2.31,22.1,22.1,0,0,1,6.4-.89q7.29,0,11.77,2.24a17,17,0,0,1,7,6.2,25.25,25.25,0,0,1,3.39,9.35A70.65,70.65,0,0,1,66.69,75v34.3H47.49V78.85c0-1.8-.07-3.65-.19-5.57A17.61,17.61,0,0,0,46.14,68a9.17,9.17,0,0,0-2.94-4,9.1,9.1,0,0,0-5.7-1.54,11.78,11.78,0,0,0-6,1.35A9.1,9.1,0,0,0,28,67.39a14.52,14.52,0,0,0-1.6,5.06A42.8,42.8,0,0,0,26,78.33v31H6.78Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path843"
                        className="logo-fill black"
                        d="M139.13,98.56a30.74,30.74,0,0,1-11.64,9.09,35,35,0,0,1-14.6,3.2,39.07,39.07,0,0,1-13.5-2.31,32.48,32.48,0,0,1-11-6.59A31,31,0,0,1,81,91.65a32.62,32.62,0,0,1-2.69-13.44A32.62,32.62,0,0,1,81,64.77a31,31,0,0,1,7.36-10.31,32.64,32.64,0,0,1,11-6.59,39.07,39.07,0,0,1,13.5-2.3,30.67,30.67,0,0,1,12.1,2.3,25.22,25.22,0,0,1,9.22,6.59A30.26,30.26,0,0,1,140,64.77a41.67,41.67,0,0,1,2.05,13.44v6H97.53a15.23,15.23,0,0,0,5,8.77A14.08,14.08,0,0,0,112,96.25a14.43,14.43,0,0,0,8-2.11,21.57,21.57,0,0,0,5.69-5.44ZM122.88,71.17a11.11,11.11,0,0,0-3.2-8.32,11.37,11.37,0,0,0-8.58-3.46,14.47,14.47,0,0,0-5.63,1A14.2,14.2,0,0,0,101.31,63a11.54,11.54,0,0,0-2.69,3.71,12.33,12.33,0,0,0-1.09,4.42Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path845"
                        className="logo-fill black"
                        d="M146.3,47.1h20.48l12.67,39h.26l10.62-39h21l11.52,39h.25l11.65-39h19.46l-22.15,62.21H213.12L200.19,68h-.26l-11.52,41.34H169.09Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path847"
                        className="logo-fill black"
                        d="M252.92,47.1H273.4l12.68,39h.25L297,47.1h21l11.52,39h.25l11.65-39h19.46l-22.15,62.21H319.74L306.81,68h-.25L295,109.31H275.71Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path849"
                        className="logo-fill black"
                        d="M359.55,47.1H380l12.67,39H393l10.62-39h21l11.52,39h.26L448,47.1h19.45l-22.14,62.21H426.36L413.44,68h-.26l-11.52,41.34H382.33Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path851"
                        className="logo-fill black"
                        d="M464.51,98.3a12.17,12.17,0,0,1,.89-4.61,11.37,11.37,0,0,1,6.28-6.27,12.36,12.36,0,0,1,9.21,0,11.31,11.31,0,0,1,6.27,6.27,12.26,12.26,0,0,1,0,9.22,11.23,11.23,0,0,1-6.27,6.27,12.23,12.23,0,0,1-9.21,0,11.36,11.36,0,0,1-3.78-2.49,11.49,11.49,0,0,1-2.5-3.78A12.13,12.13,0,0,1,464.51,98.3Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path853"
                        className="logo-fill grey"
                        d="M541.05,101.5h-.26a18,18,0,0,1-8.51,7.17,29.25,29.25,0,0,1-11.2,2.18,28.89,28.89,0,0,1-8.38-1.22,21.2,21.2,0,0,1-7.11-3.65,17.6,17.6,0,0,1-4.86-6,18.33,18.33,0,0,1-1.79-8.32,19.09,19.09,0,0,1,2-9.09,17.9,17.9,0,0,1,5.38-6.15A26.54,26.54,0,0,1,514,72.64a56.38,56.38,0,0,1,9-2,86.23,86.23,0,0,1,9.34-.76c3.11-.09,6-.13,8.64-.13a10,10,0,0,0-3.65-8.13,13.18,13.18,0,0,0-8.64-3,18.69,18.69,0,0,0-8.64,2,23.37,23.37,0,0,0-7,5.44l-10.24-10.5a35.23,35.23,0,0,1,12.54-7.49,45,45,0,0,1,14.85-2.49,38.81,38.81,0,0,1,13.89,2.11,20.27,20.27,0,0,1,8.7,6.21,23.84,23.84,0,0,1,4.54,10,65.54,65.54,0,0,1,1.28,13.76v31.62H541.05Zm-4.74-19.45c-1.45,0-3.26.06-5.44.19a27.62,27.62,0,0,0-6.27,1.09A14,14,0,0,0,519.48,86a6.24,6.24,0,0,0-2.11,5,5.5,5.5,0,0,0,2.94,5.12,12.35,12.35,0,0,0,6.15,1.66A19.38,19.38,0,0,0,531.9,97a16.16,16.16,0,0,0,4.67-2.17,10.59,10.59,0,0,0,3.26-3.59,10.31,10.31,0,0,0,1.22-5.12V82.05Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path855"
                        className="logo-fill grey"
                        d="M636,104.06q0,17.67-8.89,26.82T600.06,140a72.72,72.72,0,0,1-16.39-1.86,36.88,36.88,0,0,1-14.84-7.23l10.62-15.87a40.1,40.1,0,0,0,9.41,5.89,26.46,26.46,0,0,0,10.81,2.17q8.83,0,13-4.28a15.09,15.09,0,0,0,4.16-10.95v-6h-.26a18,18,0,0,1-8.26,6.33,26.32,26.32,0,0,1-9.28,1.86,32,32,0,0,1-12.54-2.37,27.84,27.84,0,0,1-9.66-6.66,29.59,29.59,0,0,1-6.21-10.17,38.5,38.5,0,0,1-.2-25,32.87,32.87,0,0,1,5.64-10.37A28.08,28.08,0,0,1,585,48.32a25.2,25.2,0,0,1,11.91-2.75,29.56,29.56,0,0,1,7.48.89,29.14,29.14,0,0,1,6.08,2.31A22.27,22.27,0,0,1,615,51.9a19.8,19.8,0,0,1,3.07,3.39h.26V47.1H636ZM587.64,77.82a14.93,14.93,0,0,0,1.15,5.83,15.09,15.09,0,0,0,3.27,4.86,16.59,16.59,0,0,0,4.86,3.39A14.12,14.12,0,0,0,603,93.18a14.3,14.3,0,0,0,6-1.28,16.49,16.49,0,0,0,4.92-3.39,15.09,15.09,0,0,0,3.27-4.86,15.1,15.1,0,0,0-3.27-16.52A16.86,16.86,0,0,0,609,63.74a14.46,14.46,0,0,0-6-1.28,14.27,14.27,0,0,0-6.08,1.28,17,17,0,0,0-4.86,3.39A15,15,0,0,0,588.79,72,14.85,14.85,0,0,0,587.64,77.82Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path859"
                        className="logo-fill grey"
                        d="M710.26,98.56a30.74,30.74,0,0,1-11.64,9.09,35,35,0,0,1-14.6,3.2,39.07,39.07,0,0,1-13.5-2.31,32.48,32.48,0,0,1-11-6.59,31,31,0,0,1-7.36-10.3,32.62,32.62,0,0,1-2.69-13.44,32.62,32.62,0,0,1,2.69-13.44,31,31,0,0,1,7.36-10.31,32.64,32.64,0,0,1,11-6.59,39.07,39.07,0,0,1,13.5-2.3,30.67,30.67,0,0,1,12.1,2.3,25.1,25.1,0,0,1,9.21,6.59,30.11,30.11,0,0,1,5.83,10.31,41.67,41.67,0,0,1,2.05,13.44v6H668.66a15.23,15.23,0,0,0,5,8.77,14.08,14.08,0,0,0,9.47,3.26,14.43,14.43,0,0,0,8-2.11,21.57,21.57,0,0,0,5.69-5.44ZM694,71.17a11.11,11.11,0,0,0-3.2-8.32,11.37,11.37,0,0,0-8.58-3.46,14.47,14.47,0,0,0-5.63,1A14.2,14.2,0,0,0,672.44,63a11.54,11.54,0,0,0-2.69,3.71,12.33,12.33,0,0,0-1.09,4.42Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path857"
                        className="logo-fill grey"
                        d="M724.85,47.1h18.44v8.45h.25A15.6,15.6,0,0,1,746.1,52a18.43,18.43,0,0,1,4-3.2,22.7,22.7,0,0,1,5.25-2.31,22.15,22.15,0,0,1,6.4-.89q7.29,0,11.77,2.24a17,17,0,0,1,7,6.2,25.25,25.25,0,0,1,3.39,9.35,70.65,70.65,0,0,1,.9,11.65v34.3h-19.2V78.85c0-1.8-.07-3.65-.19-5.57A17.61,17.61,0,0,0,764.21,68a8.26,8.26,0,0,0-8.64-5.51,11.78,11.78,0,0,0-6,1.35A9.1,9.1,0,0,0,746,67.39a14.52,14.52,0,0,0-1.6,5.06,42.8,42.8,0,0,0-.39,5.88v31h-19.2Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path857"
                        className="logo-fill grey"
                        d="M840.44,67.2a10.73,10.73,0,0,0-4-3.39A11.64,11.64,0,0,0,831,62.46q-7.29,0-11.32,4.35c-2.69,2.91-4,6.7-4,11.4s1.35,8.49,4,11.39S826.1,94,831,94a10.36,10.36,0,0,0,5.51-1.41,17,17,0,0,0,4-3.33l12.67,13.32A24.56,24.56,0,0,1,842.55,109,39.62,39.62,0,0,1,831,110.85a39.07,39.07,0,0,1-13.5-2.31,32.48,32.48,0,0,1-11-6.59,31.12,31.12,0,0,1-7.36-10.3,32.62,32.62,0,0,1-2.68-13.44,32.62,32.62,0,0,1,2.68-13.44,31.16,31.16,0,0,1,7.36-10.31,32.64,32.64,0,0,1,11-6.59,39.07,39.07,0,0,1,13.5-2.3,39.93,39.93,0,0,1,11.59,1.85,24.48,24.48,0,0,1,10.56,6.47Z"
                        transform="translate(-6.78 -45.57)"/>
                    <path
                        id="path857"
                        className="logo-fill grey"
                        d="M895.86,119.42q-1.92,5-3.71,8.83a21.25,21.25,0,0,1-4.55,6.47,17.73,17.73,0,0,1-7,4A38,38,0,0,1,869.49,140a45.12,45.12,0,0,1-14.33-2.3L857.72,122a22,22,0,0,0,9,1.92,16.35,16.35,0,0,0,5.24-.7,8.71,8.71,0,0,0,3.4-2,11.56,11.56,0,0,0,2.24-3.26c.59-1.28,1.23-2.78,1.92-4.48l1.4-3.59L853.62,47.1h20.74l16,41.09h.25l13.7-41.09H924Z"
                        transform="translate(-6.78 -45.57)"/>
                  </g>
                </svg>
              </NavbarBrand>
              <p className="footer-description">
                <FormattedMessage id="f_desc" />
              </p>

            </div>
            <div className="footer-container">
              <nav className="footer-nav">
                <ul>
                  <li className="headline"><FormattedMessage id="agency" /></li>
                  <li>
                    <a
                      href="#services"
                      className="link"
                      onClick={e => {
                        e.preventDefault();
                        handleLink('services');
                      }}
                    >
                      <FormattedMessage id="services" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#work"
                      className="link"
                      onClick={e => {
                        e.preventDefault();
                        handleLink('work');
                      }}
                    >
                      <FormattedMessage id="work" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about"
                      className="link"
                      onClick={e => {
                        e.preventDefault();
                        handleLink('about');
                      }}
                    >
                      <FormattedMessage id="about" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer-container">
              <nav className="footer-nav">
                <ul>
                  <li className="headline"><FormattedMessage id="links" /></li>
                  <li>
                    <Link
                        className="link"
                        to={getCurrentLink(props.langs, '/e-commerce-online-shop')}
                    >
                      <FormattedMessage id="ecommerce" />
                    </Link>
                  </li>
                  <li>
                    <Link
                        className="link"
                        to={getCurrentLink(props.langs, '/ux-ui-design')}
                    >
                      <FormattedMessage id="ux_ui_design" />
                    </Link>
                  </li>
                  <li>
                    <Link
                        className="link"
                        to={getCurrentLink(props.langs, '/websites')}
                    >
                      <FormattedMessage id="websites" />
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer-container">
              <nav className="footer-nav">
                <ul>
                  <li className="headline"><FormattedMessage id="legal" /></li>
                  <li>
                    <Link
                        className="link"
                        to={getCurrentLink(props.langs, '/privacy')}
                    >
                      <FormattedMessage id="privacy" />
                    </Link>
                  </li>
                  <li>
                    <Link
                        className="link"
                        to={getCurrentLink(props.langs, '/about')}
                    >
                      <FormattedMessage id="about_us" />
                    </Link>
                  </li>
                  <li>
                    <a
                        href="#contact"
                        className="link"
                        onClick={e => {
                          e.preventDefault();
                          handleLink('contact');
                        }}
                    >
                      <FormattedMessage id="contact" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="footer-container">
              <nav className="footer-nav">
                <ul>
                  <li className="headline"><FormattedMessage id="social" /></li>
                  <li>
                    <a className="link" href="https://dribbble.com/vincentabel" rel="noreferrer" target="_blank">Dribbble</a>
                  </li>
                  <li>
                    <a className="link" href="https://facebook.com/team.newww.agency" rel="noreferrer" target="_blank">Facebook</a>
                  </li>
                  <li>
                    <a className="link" href="https://instagram.com/newww.agency" rel="noreferrer" target="_blank">Instagram</a>
                  </li>
                </ul>
              </nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm="12">
            <hr className="bottom" />
            <div className="sub-footer">
              <p className="copyright-container">
                © 2021 YourProject GmbH. <FormattedMessage id="all_rights_reserved" />
              </p>
              <LanguageSwitch langs={props.langs}/>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
