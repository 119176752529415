import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export function getCurrentLink(langs, link) {
  let currentLanguage = 'de';
  langs.map(lang => {
    if (lang.selected) {
      currentLanguage = lang.langKey;
    }
    return null;
  });

  if (currentLanguage === 'de') {
    return link;
  } else {
    return '/en' + link;
  }
}

export function getCurrentLanguage(langs) {
  let currentLanguage = 'de';
  langs.map(lang => {
    if (lang.selected) {
      currentLanguage = lang.langKey;
    }
    return null;
  });

  if (currentLanguage === 'de') {
    return 'de';
  } else {
    return 'en';
  }
}

export function getCurrentLinkSwitch(langs, link) {
  let currentLanguage = 'de';
  langs.map(lang => {
    if (lang.selected) {
      currentLanguage = lang.langKey;
    }
    return null;
  });

  if (currentLanguage === 'de') {
    return '/' + link[0];
  } else {
    return '/en/' + link[1];
  }
}

function getLanguageName(str) {
  if (str === 'de') {
    return 'Deutsch';
  } else {
    return 'English';
  }
}

const LanguageSwitch = (props) => {
  const links = props.langs.map((lang) => {
    let langLink = lang.link;
    if (langLink === '/find-out-your-website-price') {
      langLink = '/webseiten-preis-herausfinden';
    } else if  (langLink === '/en/webseiten-preis-herausfinden') {
      langLink = '/en/find-out-your-website-price';
    }
    return (
      <li key={lang.langKey} className={lang.selected ? "selected" : ""}>
        <Link className="link" to={langLink}>
          {getLanguageName(lang.langKey)}
        </Link>
      </li>
    );
  });

  return (
    <ul id="language-switch">
      {links}
    </ul>
  );
};

LanguageSwitch.propTypes = {
  langs: PropTypes.array
};

export default LanguageSwitch;
