import React from 'react'
import "../assets/css/bootstrap.min.css";
import "../assets/css/main.css";
import ColorNavbar from '../components/colornavbar'
import ResizeContainer from '../components/resizecontainer'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n/dist';
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from 'react-intl';
import 'intl';
import Footer from "../components/footer";
import smoothscroll from 'smoothscroll-polyfill';
import ogImage from "../images/facebook-titelbild.png";

const Layout = ({ props, children, location, i18nMessages, title, description, keywords, robots, classNameChild }) => {
  if (typeof window !== `undefined`) {
    smoothscroll.polyfill();
  }
  React.useEffect(() => {
    const updateView = () => {
      let descriptionItems = document.querySelectorAll(".section-header h3.header-subheadline, .section-header h1.header-headline, .section-header .link, .section-header .header-list li, .section-header .img-wrapper, .section-line .col-lg-3, .section-knowledge h3.knowledge-subheadline, .section-knowledge h2.knowledge-headline, .section-knowledge p.knowledge-paragraph, .section-knowledge .link, .section-knowledge .img-wrapper, .link-btn, .create-contact, h2.description, h3.title, .card.plain, .card.about, .card.icon, .card.large, .box, .ecommerce .section .container h2.headline, .ecommerce .section .container p.description, .websites .section .container h2.headline, .uxuidesign .section .container p.description, .uxuidesign .section .container h2.headline, .websites .section .container p.description, .create-contact");
      const windowHeight = window.innerHeight;
      for (let i = 0; i < descriptionItems.length; i++) {
        if ((descriptionItems[i].classList.contains("card") && windowHeight - (descriptionItems[i].getBoundingClientRect().top - (windowHeight * 0.15)) - (descriptionItems[i].getBoundingClientRect().height * 0.2) > 0) || (!descriptionItems[i].classList.contains("card") && windowHeight - descriptionItems[i].getBoundingClientRect().top - (descriptionItems[i].getBoundingClientRect().height * 0.2) > 0)) {
          if (!descriptionItems[i].classList.contains("visible")) {
            descriptionItems[i].classList.add("visible");
          }
        }
      }
    };
    updateView();
    if (typeof window !== `undefined` && ['/', '/en/', '/de/', '/websites', '/websites/', '/en/websites', '/en/websites/', '/e-commerce-online-shop', '/e-commerce-online-shop/', '/en/e-commerce-online-shop', '/en/e-commerce-online-shop/', '/ux-ui-design', '/ux-ui-design/', '/en/ux-ui-design', '/en/ux-ui-design/'].includes(window.location.pathname)) {
      window.addEventListener("scroll", updateView);
    }
    return () => {
      if (typeof window !== `undefined`) {
        if (['/', '/en/', '/de/', '/websites', '/websites/', '/en/websites', '/en/websites/', '/e-commerce-online-shop', '/e-commerce-online-shop/', '/en/e-commerce-online-shop', '/en/e-commerce-online-shop/', '/ux-ui-design', '/ux-ui-design/', '/en/ux-ui-design', '/en/ux-ui-design/'].includes(window.location.pathname)) {
          window.removeEventListener("scroll", updateView);
        }
      }
    };
  });
  function getHelmet(lang, currentLang) {
    return (
      <Helmet
        title={title}
        meta={[
          { name: 'viewport', content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' },
        ]}
        htmlAttributes={{
          lang,
        }}
      >
        <script type="text/plain" className="cmplazyload" data-cmp-vendor="s905">
          {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-PN7VBX5');
            `}
        </script>

        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta httpEquiv="Content-Language" content={currentLang} />
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:type" content="website" />
        <meta name="og:image" content={`https://newww.agency/${ogImage}`} />
        <meta name="keywords" content={keywords + 'newww.agency'} />
        <meta name="robots" content={robots !== '' ? robots : 'noindex, follow, notranslate'} />
        <meta name="author" content="Vincent Abel" />
        <link rel="canonical" href={location.href} />
      </Helmet>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
        const lang = langKey;
        let currentLang = '';
        if (langKey === 'de') {
          currentLang = 'de-DE';
        } else {
          currentLang = 'en-US';
        }
        return (
          <IntlProvider
            locale={langKey}
            messages={i18nMessages}
          >
            <div>
              {getHelmet(lang, currentLang)}
              <ColorNavbar classNameChild={classNameChild ? classNameChild : null} langs={langsMenu} />
              <ResizeContainer />
              <div className={classNameChild ? classNameChild : null}>
                {children}
              </div>
              <Footer langs={langsMenu} />
            </div>
          </IntlProvider>
        )
      }}
    />
  );
};

export default Layout
